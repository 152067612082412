import React from 'react';
import '../assets/css/termsconditions.css'; 
import { Navbar } from 'react-bootstrap';
import Footer from '../components/Footer';

const TermsAndConditions = () => {
  return (
    <>
    <Navbar/>
    <div className="terms-container">
      <h1>SEARCH PATTERNS - TERMS AND CONDITIONS</h1>
      <p>
        WELCOME TO SEARCH PATTERNS
        Thank you for visiting www.SearchPatterns.com (the Site). The Site is owned and operated by Search Patterns Ltd trading as Search Patterns (us, we, or Search Patterns).
      </p>
      <h2>THESE TERMS AND CONDITIONS</h2>
      <p>
        These terms and conditions (together with any documents referred to in them and any additional terms on the Site) (Terms and Conditions) set out the basis on which we operate our Site. By accessing and using our Site, you agree to be bound by these Terms and Conditions. Please read them carefully. If you do not agree to these Terms and Conditions, please do not use our Site.
      </p>
      <h2>CHANGES TO THESE TERMS</h2>
      <p>
        We may revise these Terms and Conditions at any time by updating this page. Please check this page regularly to ensure you are familiar with the current version. Your continued use of the Site after any changes indicates your acceptance of the updated Terms and Conditions.
      </p>
      <h2>INFORMATION ABOUT SEARCH PATTERNS AND OUR SITE</h2>
      <p>
        Search Patterns Ltd (t/a Search Patterns) is a company registered in [Country] under company number [number] with our registered office at [Address]. Contact details are available on the Site.
      </p>
      <p>
        Search Patterns is an online search insight tool. Our Site includes elements provided by third parties. We reserve the right to restrict or charge for use of our Site according to certain usage levels as detailed on the Site and in these Terms and Conditions.
      </p>
      <h2>AVAILABILITY</h2>
      <p>
        While we strive to ensure our Site is available 24/7, we do not guarantee uninterrupted access. Temporary interruptions may occur due to factors beyond our control, such as equipment, software, or network issues.
      </p>
      <h2>PRO PLAN SUBSCRIPTION</h2>
      <p>
        Our Pro Plan offers unlimited searches on a rolling [monthly] subscription basis. By subscribing, you agree to pay the applicable charges. Payment must be received before your Pro Plan can commence. We reserve the right to suspend or cancel your Pro Plan for late payment.
      </p>
      <p>
        Your Pro Plan is valid for the stated subscription period. It will automatically renew at the prevailing rate unless canceled online before the renewal date. We may change subscription charges at our discretion for unpaid periods.
      </p>
      <h2>RESTRICTIONS</h2>
      <p>
        Each Pro Plan login is for a single named user and is non-transferable. Sharing logins is prohibited. We may levy additional charges or suspend/terminate your Pro Plan if logins are shared.
      </p>
      <p>
        By subscribing to a Pro Plan, you warrant that you are authorized to do so and that payment information provided is accurate. You are responsible for all applicable taxes. By purchasing, you confirm legal capacity to enter into contracts, are at least 18 years old, and are making purchases for business purposes.
      </p>
      <h2>REFERRAL PROGRAM</h2>
      <p>
        Periodically, we may offer a referral program. Participants receive a unique code to share. New customers using your code receive a % discount on their first month, and you earn a % discount on your next month’s subscription, as per program terms.
      </p>
      <h2>SERVICE LEVELS</h2>
      <p>
        We aim to maintain reasonable availability of the Site but do not guarantee specific response times or availability. Third-party functionality is beyond our control, and we disclaim warranties or liabilities related to its performance.
      </p>
      <h2>INTELLECTUAL PROPERTY AND USAGE LICENSE</h2>
      <p>
        Our Site contains valuable intellectual property owned by us and/or our licensors, protected by various laws. You may use our Site content for personal use only. Reproduction, adaptation, distribution, or storage is prohibited without our prior written consent.
      </p>
      <p>
        Subscribers to our Pro Plan receive a limited, non-exclusive, non-transferable license to use our Site’s intellectual property for business purposes during the subscription period. Commercial use beyond this scope is prohibited.
      </p>
      <h2>THIRD PARTY INTELLECTUAL PROPERTY</h2>
      <p>
        Our Site may use third-party copyrighted materials and trademarks editorially. No association with third parties is implied. Unauthorized use of third-party trademarks is prohibited without their consent.
      </p>
      <h2>CONFIDENTIAL INFORMATION</h2>
      <p>
        Communications between us are confidential unless marked otherwise or considered confidential. We may publish your use of the Site in accordance with honest trade practices.
      </p>
      <h2>GENERAL SITE USAGE GUIDELINES</h2>
      <p>
        Users must use our Site lawfully, respectfully, and in accordance with our guidelines. We reserve the right to suspend or cancel access for breaches of these guidelines.
      </p>
      <h2>COMPLAINTS PROCEDURE</h2>
      <p>
        To report breaches of our guidelines, email support@searchpatterns.com or write to us at [Address], providing specific details to assist in our investigation.
      </p>
      <h2>SITE SECURITY</h2>
      <p>
        You are responsible for maintaining the security of your login details. Sharing logins is prohibited. We may suspend access if security breaches are suspected.
      </p>
      <h2>PRIVACY POLICY</h2>
      <p>
        We comply with data protection laws. Subscription Data submitted through our Site is subject to our Privacy Policy, available at [Privacy Policy URL].
      </p>
      <h2>DEALINGS WITH THIRD PARTIES</h2>
      <p>
        Links to third-party sites are for information only. We do not endorse or control them and disclaim liability for their content or use.
      </p>
      <h2>LIMITATION OF LIABILITY</h2>
      <p>
        We are not liable for any indirect, incidental, or consequential damages arising from your use of our Site. Our liability is limited to the sums paid for via the Site in the preceding six months.
      </p>
      <h2>INDEMNITY</h2>
      <p>
        You agree to indemnify us against liabilities, claims, and expenses arising from breaches of these Terms and Conditions by you.
      </p>
      <h2>DURATION AND TERMINATION</h2>
      <p>
        These Terms and Conditions apply from your first visit to our Site. They remain in force until termination. We may terminate or suspend your access at any time for any reason.
      </p>
      <h2>GENERAL</h2>
      <p>
        These Terms and Conditions constitute the entire agreement between us. They are governed by [Country] law, and any disputes are subject to the exclusive jurisdiction of the [Country] courts.
      </p>
    </div>
    <Footer/>
    
    </>
  );
};

export default TermsAndConditions;
