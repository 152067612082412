import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: 'querydata',
  initialState: {
    queryResponse: [],
    queryHistory: {},
    summaryid: null,
    isMainContentVisible: true,
    chats: {},
    currentChatId: null,
    isSidebarClosed: false,
    prompt:null
  },
  reducers: {
    setQueryResponse: (state, action) => {
      if (action.payload) {
        state.queryResponse.push({ ...action.payload, timestamp: new Date().toISOString() });
      } else {
        state.queryResponse = [];
      }
    },
    setQueryHistory: (state, action) => {
      const { chatId, historyData } = action.payload;

      if (chatId) {
        // Update specific chatId in queryHistory
        state.queryHistory[chatId] = historyData;
        // Synchronize chats state with queryHistory
        if (state.chats[chatId]) {
          state.chats[chatId].queryResponseList = historyData;
        } else {
          state.chats[chatId] = { queryResponseList: historyData, currentIndex: -1, summary_id: null, summary: null };
        }
      } else {
        // Update entire queryHistory
        state.queryHistory = action.payload;
        // Synchronize chats state with queryHistory
        state.chats = Object.keys(action.payload).reduce((acc, key) => {
          acc[key] = state.chats[key] || { queryResponseList: action.payload[key], currentIndex: -1, summary_id: null, summary: null };
          return acc;
        }, {});
      }
    },
    setSummaryid: (state, action) => {
      state.summaryid = action.payload;
    },
    setIsMainContentVisible: (state, action) => {
      state.isMainContentVisible = action.payload;
    },
    setChats: (state, action) => {
      const { chatId, chatData } = action.payload;
      if (chatId) {
        state.chats[chatId] = chatData;
        // Synchronize queryHistory state with chats
        state.queryHistory[chatId] = chatData.queryResponseList;
      }
    },
    setCurrentChatId: (state, action) => {
      state.currentChatId = action.payload;
    },
    setIsSidebarClosed: (state, action) => {
      state.isSidebarClosed = action.payload;
    },
    setPrompt:(state,action)=>{
      state.prompt=action.payload;
    }
  },
});

export const {
  setQueryHistory,
  setQueryResponse,
  setSummaryid,
  setIsMainContentVisible,
  setChats,
  setCurrentChatId,
  setIsSidebarClosed,
  setPrompt
} = slice.actions;

export default slice.reducer;
