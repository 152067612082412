import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Seo from '../pages/Seo';
import Chart from '../components/Chart';
import Feature from '../pages/Feature';
import Table from '../components/Table';
import Contentmarketing from '../pages/ContentMarketing';
import Consumerresearch from '../pages/ConsumerResearch';
import Signup from '../pages/Signup';
import Loader from '../components/Loader';
import SearchAI from '../pages/AI/SearchAI';
import FAQ from '../pages/Faq';
import ScrollToTop from '../components/ScrollToTop';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsConditions';
import PageTransition from '../components/PageTransition';
import Discover from '../pages/AI/Discover';

const Home = lazy(() => import('../pages/Home'));
const Search = lazy(() => import('../pages/Search'));
const Login = lazy(() => import('../pages/Login'));
const Pricing = lazy(() => import('../pages/Pricing'));
const Consulting = lazy(() => import('../pages/Consulting'));

export default function Routess() {
  const location = useLocation();
  return (
    <Suspense fallback={<Loader />}>
      <ScrollToTop />
      {/* <PageTransition location={location}> */}
        <Routes location={location} key={location.pathname}>
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/' element={<Home />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/consulting' element={<Consulting />} />
          <Route path='/seo' element={<Seo />} />
          <Route path='/chart' element={<Chart />} />
          <Route path='/searchbox' element={<Search />} />
          <Route path='/content_marketing' element={<Contentmarketing />} />
          <Route path='/consumer_research' element={<Consumerresearch />} />
          <Route path='/feature' element={<Feature />} />
          <Route path='/table' element={<Table />} />
          <Route path='/loader' element={<Loader />} />
          <Route path='/searchAi/:q' element={<SearchAI />} />
          <Route path='/searchAi/faq' element={<FAQ />} />
          <Route path='/privacyandpolicy' element={<PrivacyPolicy />} />
          <Route path='/termsandconditions' element={<TermsAndConditions />} />
          <Route path='/searchAi/discover' element={<SearchAI />} />

        </Routes>
      {/* </PageTransition> */}
    </Suspense>
  );
}