import './App.css';

import Routes from './routes/Routes';

const App = () => {  
  return (
    <>
      <Routes/>
    </>
  );
};

export default App;
