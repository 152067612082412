const BASE_URL = `${process.env.REACT_APP_BACKEND_API}`

// AUTH ENDPOINTS
export const endpoints = {
  SEARCH_API: BASE_URL + "/api/search",
  PREPOSITION_API: BASE_URL + "/api/preposition",
  COMPARISION_API: BASE_URL + "/api/comparison",
  ALPHABET_API: BASE_URL + "/api/alphabet",
  AI_API:BASE_URL+ "/api/get-answer",
  SENDOTP_API: BASE_URL+ "/api/send-otp",
  VERIFYOTP_API: BASE_URL+ "/api/verify-otp",
}
