import axios from "axios";
import { extractSummaryText, formatAnswer, formatRelatedQuestions } from "../../utils/aihelper";
import { endpoints } from "../api";

export function fetchPromptFromAI(searchQuery, summaryid) {
  return async (dispatch) => {
    let images = [];

    try {
      const imageResponse = await axios({
        method: "GET",
        url: "https://real-time-image-search.p.rapidapi.com/search",
        params: {
          query: searchQuery,
          size: 'any',
          color: 'any',
          type: 'any',
          time: 'any',
          usage_rights: 'any',
          file_type: 'any',
          aspect_ratio: 'any',
          safe_search: 'off',
          region: 'us'
        },
        headers: {
          'x-rapidapi-key': '79afb559f4msh7f31254dd73c97cp19ca63jsn1864a5c946bb',
          'x-rapidapi-host': 'real-time-image-search.p.rapidapi.com'
        }
      });

      images = imageResponse.data.data?.slice(0, 6);
    } catch (error) {
      console.log("Error while fetching images", error);
      images = []; // Set images to an empty array if fetching images fails
    }

    try {
      let response;

      if (summaryid) {
        response = await axios.post(
          `${endpoints.AI_API}`,
          { prompt: searchQuery, summary_id: summaryid }
        );
      } else {
        response = await axios.post(
          `${endpoints.AI_API}`,
          { prompt: searchQuery }
        );
      }

      const newQuery = { query: searchQuery, answer: response.data.response, images, isPinned: false };

      const processedSummary = response.data.query_summary.includes(':')
        ? response.data.query_summary.split(':')[1].trim()
        : response.data.query_summary;

      const newQueryWithSummaryAndQuestions = {
        ...newQuery,
        summary: processedSummary,
        relatedQuestions: response.data.related_queries,
        summary_id: response.data.summary_id
      };

      return newQueryWithSummaryAndQuestions;
    } catch (error) {
      console.log("Error while fetching Prompt from AI", error);
      const newQuery = { query: searchQuery, answer: "Sorry !! There seems to be a problem from our side", isPinned: false };
      const newQueryWithSummaryAndQuestions = {
        ...newQuery,
        summary: null,
        relatedQuestions: null,
        summary_id: null
      };

      return newQueryWithSummaryAndQuestions;
    }
  };
}
