import React, { useContext, useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import { SearchContext } from "../contextApi/SearchContext";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "./SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { setPrompt } from "../slices/query";

function Table() {
  const { loading } = useContext(SearchContext);
  const [chartsLoaded, setChartsLoaded] = useState(false);
  const [selected, setSelected] = useState("IN");
  const chrt = useRef(null);
  const chrtcompare = useRef(null);
  const chartprepos = useRef(null);
  const suggestions = useSelector((state) => state.searchdata.suggestions);
  const prepositions = useSelector((state) => state.searchdata.prepositions);
  const comparisons = useSelector((state) => state.searchdata.comparisons);

  const showQuestionTable = useSelector((state) => state.showvariables.showQuestionsTable);
  const showPrepositionsTable = useSelector((state) => state.showvariables.showPrepositionsTable);
  const showComparisonTable = useSelector((state) => state.showvariables.showComparisonTable);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTableClick = (query) => {
    dispatch(setPrompt(query));
    navigate(`/searchAi/q=query`);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setChartsLoaded(true);
      }, 2000);
    }
  }, [loading]);

  return (
    <>
      <Navbar />
      <SearchBar selected={selected} setSelected={setSelected} />
      <div id="apidatatable">
        {showQuestionTable && (
          <h2 className="text-white text-center my-5" style={{ fontFamily: "denton" }} ref={chrt}>
            Questions
          </h2>
        )}
        <div className="row">
          {showQuestionTable &&
            suggestions &&
            Object.entries(suggestions).map(([key, values]) =>
              values ? (
                <div key={key} className="col-md-4 mb-4">
                  <strong className="text-white" style={{ fontFamily: "gilroy", fontSize: "20px", marginLeft: "15px" }}>
                    {key}:
                  </strong>
                  <ul className="pl-3" style={{ fontFamily: "gilroy" }}>
                    {values.map((value, index) => (
                      <li key={index}>
                        <div
                          className="google-link text-white"
                          onClick={() => handleTableClick(value)}
                        >
                          {value}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div key={key}></div>
              )
            )}
        </div>

        {showPrepositionsTable && (
          <h2 className="text-white text-center my-5" ref={chartprepos}>
            Prepositions
          </h2>
        )}
        <div className="row">
          {showPrepositionsTable &&
            prepositions &&
            Object.entries(prepositions).map(([key, values]) =>
              values ? (
                <div key={key} className="col-md-4 mb-4">
                  <strong className="text-white">{key}:</strong>
                  <ul className="pl-3">
                    {values.map((value, index) => (
                      <li key={index}>
                        <div
                          className="google-link text-white"
                          onClick={() => handleTableClick(value)}
                        >
                          {value}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div key={key}></div>
              )
            )}
        </div>

        {showComparisonTable && (
          <>
            <h2 className="text-white text-center my-5" ref={chrtcompare}>
              Comparisons
            </h2>
          </>
        )}
        <div className="row">
          {showComparisonTable &&
            comparisons &&
            Object.entries(comparisons).map(([key, values]) =>
              values.length > 0 ? (
                <div key={key} className="col-md-4 mb-4">
                  <strong className="text-white">{key}:</strong>
                  <ul className="pl-3">
                    {values.map((value, index) => (
                      <li key={index}>
                        <div
                          className="google-link text-white"
                          onClick={() => handleTableClick(value)}
                        >
                          {value}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div key={key}></div>
              )
            )}
        </div>
      </div>
    </>
  );
}

export default Table;
