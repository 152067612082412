import React, { useContext, useEffect, useState, useRef } from 'react';
import { SearchContext } from '../contextApi/SearchContext';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import videoFile from "../assets/videos/hero-desktop.mp4";
import {
  setShowComparison,
  setShowComparisonTable,
  setShowPrepositions,
  setShowPrepositionsTable,
  setShowQuestions,
  setShowQuestionsTable
} from '../slices/show';
import { setPrepositions, setUseCustomPrompt } from '../slices/search';
import { setPrompt } from '../slices/query';

export default function SearchBar({ selected, setSelected }) {
  const { handleInputChange, handleSubmit, query } = useContext(SearchContext);
  const [showButtons, setShowButtons] = useState(false);
  const [activeButton, setActiveButton] = useState(null); // State to track active button
  const [customPrompt, setCustomPrompt] = useState(''); // State for custom prompt
      // const [useCustomPrompt, setUseCustomPrompt] = useState(false); // State for toggle switch
  const hasSearched = useSelector((state) => state.searchdata.hasSearched);
  const chartsLoaded = useSelector((state) => state.searchdata.chartsLoading);
  const useCustomPrompt = useSelector((state) => state.searchdata.useCustomPrompt);
  const authdata = useSelector((state) => state.authdata.authData);
  const searchCount=useSelector((state)=> state.searchdata.searchCount);
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const navigate=useNavigate();
  useEffect(() => {
    if (!authdata && searchCount >= 10000000) {
      window.location.href = '/';
    }
  }, [searchCount]);


  const handleQuestionClick = () => {
    dispatch(setShowQuestions(true));
    dispatch(setShowPrepositions(false));
    dispatch(setShowComparison(false));
    dispatch(setShowQuestionsTable(true));
    dispatch(setShowPrepositionsTable(false));
    dispatch(setShowComparisonTable(false));
    setActiveButton('questions'); // Set active button state
  };

  const handlePrepositionsClick = () => {
    dispatch(setShowQuestions(false));
    dispatch(setShowPrepositions(true));
    dispatch(setShowComparison(false));
    dispatch(setShowQuestionsTable(false));
    dispatch(setShowPrepositionsTable(true));
    dispatch(setShowComparisonTable(false));
    setActiveButton('prepositions'); // Set active button state
  };

  const handleComparisonClick = () => {
    dispatch(setShowQuestions(false));
    dispatch(setShowPrepositions(false));
    dispatch(setShowComparison(true));
    dispatch(setShowQuestionsTable(false));
    dispatch(setShowPrepositionsTable(false));
    dispatch(setShowComparisonTable(true));
    setActiveButton('comparison'); // Set active button state
  };

  const handleSearchClick=(event)=>{
    if(!useCustomPrompt){
      handleSubmit(event);
    }else{
      handleProSubmit(event);
    }
  }
  const handleProSubmit=(e)=>{
    e.preventDefault();
    if(customPrompt.length>0){
      dispatch(setPrompt(customPrompt));
      
      setTimeout(() => {
        navigate(`/searchAi/q=query`)
      }, 1000);
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!useCustomPrompt) {
        handleSubmit(e);
      } else {
        handleProSubmit(e);
      }
    }
  };
  
  useEffect(() => {
    if (chartsLoaded) {
      setShowButtons(true);
    }
  }, [chartsLoaded]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = '48px';
      inputRef.current.style.height = `${Math.min(inputRef.current.scrollHeight, 300)}px`;
    }
  }, [customPrompt, query, useCustomPrompt]);

  return (
    <div id="search">
      <div style={{ marginBottom: '0' }}>
        <video autoPlay muted loop id="background-video">
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="search-container">
          <div className="overlay">
            <h1 className="mt-5">Building bridges in the <span>digital</span> landscape...</h1>
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-md-10">
                  <div className="card">
                    <div className="row justify-content-center pt-0 pb-0">
                      <div className="col-md-10">
                        <div className="search" query={query} handleInputChange={handleInputChange} >
                          <div className='search-bar' style={{backgroundColor:"white"}}>
                          <form action="" style={{ position: 'relative',display:"flex",float:"none",backgroundColor:"transparent" }} onSubmit={handleSearchClick}>
                            <textarea
                              ref={inputRef}
                              className="search-input text-black"
                              value={useCustomPrompt ? customPrompt : query}
                              onKeyPress={handleKeyPress}
                              onChange={(e) => useCustomPrompt ? setCustomPrompt(e.target.value) : handleInputChange(e)}
                              placeholder={useCustomPrompt ? "Shoot your prompt here..." : "Type something..."}
                              style={{ height: '50px !important' }} // Set the fixed height here
                            />
                            <div>
                            <button type='submit' className="search-icon" ><i className="bi bi-search" style={{ fontSize: 'large' }}></i></button>                         
                            </div>
                            </form>
                          </div>
                          <div className="toggle-switch-container mt-3" style={{ position: 'relative', height: '1.5em' }}>
                            <p className={`heading mt-1 mb-0 text-center transition-text ${useCustomPrompt ? 'hidden' : ''}`}>
                              Use 1-2 words for best results
                            </p>
                            <p className={`heading mt-1 mb-0 text-center transition-text ${useCustomPrompt ? '' : 'hidden'}`}>
                              Switch to pro mode
                            </p>

                            <label className="switch mt-1">
                              <input type="checkbox" checked={useCustomPrompt} onChange={() => dispatch(setUseCustomPrompt(!useCustomPrompt))} />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {chartsLoaded && !useCustomPrompt && (
        <div className={`container ${chartsLoaded ? '' : ''}`}>
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                className={`btn btn-outline-light me-2 mt-3 prefixbtn ${activeButton === 'questions' ? 'active' : ''}`}
                onClick={handleQuestionClick}
                disabled={!showButtons}
              >
                Questions
              </button>
              <button
                type="button"
                className={`btn btn-outline-light me-2 mt-3 prefixbtn ${activeButton === 'prepositions' ? 'active' : ''}`}
                onClick={handlePrepositionsClick}
                disabled={!showButtons}
              >
                Prepositions
              </button>
              <button
                type="button"
                className={`btn btn-outline-light me-2 mt-3 prefixbtn ${activeButton === 'comparison' ? 'active' : ''}`}
                onClick={handleComparisonClick}
                disabled={!showButtons}
              >
                Comparisons
              </button>
            </div>
          </div>
        </div>
      )}
      {chartsLoaded && !useCustomPrompt && (
        <div className={`container ${chartsLoaded ? 'sticky-buttons-container' : ''}`}>
          <div className="row">
            <div className="col-md-12">
              <Link to="/searchbox">
                <button type="button" className="btn btn-outline-light me-2 mt-3 prefixbtn" disabled={!showButtons}>
                  Chart
                </button>
              </Link>
              <Link to="/table">
                <button type="button" className="btn btn-outline-light me-2 mt-3 prefixbtn" disabled={!showButtons}>
                  Table
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}