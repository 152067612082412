import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import store from './store/store';
import {Provider} from 'react-redux';
import SearchContext from './contextApi/SearchContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';

const clientId="453103766731-nhv5i8kjeav683iue38sdis5vqfnmogj.apps.googleusercontent.com"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <BrowserRouter>
  <Provider store={store}>
      <SearchContext>
<GoogleOAuthProvider clientId={clientId}>
    <App />
</GoogleOAuthProvider>
      </SearchContext>
    </Provider>
  </BrowserRouter>
  
 
);

