import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/search petterns logo1.png';
import { useDispatch, useSelector } from 'react-redux';
import { googleLogout } from '@react-oauth/google';
import { setAuthData } from '../slices/auth';
import { PiDotOutlineFill } from "react-icons/pi";
import { noAuto } from '@fortawesome/fontawesome-svg-core';

function Navbar({ onLoginClick }) {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [showPopup, setShowPopup] = useState(true);
  const historyPopupRef = useRef(null);
  const authdata = useSelector((state) => state.authdata.authData);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleClickOutside(event) {
      if (historyPopupRef.current && !historyPopupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark" id="navbar">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} alt="" style={{ width: "300px", paddingTop: '13px' }} />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " isactive" : "")} to="/">Home</NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color:'white'}}>
                  Use Cases
                </NavLink>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown">
                  <li><NavLink className={({ isActive }) => "dropdown-item" + (isActive ? " isactive" : "")} to="/seo"><i className="bi bi-search me-3"></i>SEO</NavLink></li>
                  <li><NavLink className={({ isActive }) => "dropdown-item" + (isActive ? " isactive" : "")} to="/content_marketing"><i className="bi bi-file-earmark-medical me-3"></i>Content Marketing</NavLink></li>
                  <li><NavLink className={({ isActive }) => "dropdown-item" + (isActive ? " isactive" : "")} to="/consumer_research"><i className="bi bi-people-fill me-3"></i>Consumer Research</NavLink></li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " isactive" : "")} to="/feature">Features</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " isactive" : "")} to="/pricing">Pricing</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " isactive" : "")} to="/consulting">Consulting</NavLink>
              </li>
            </ul>
            <form className="d-flex" style={{ paddingTop: '13px' }}>
              {!authdata ? (
                <>
                  <button type="button" className="btn btn-outline-light me-2" onClick={onLoginClick}>Login</button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-light me-2"
                    onClick={() => {
                      localStorage.removeItem('token');
                      localStorage.removeItem('authData');
                      googleLogout();
                      setToken('');
                      dispatch(setAuthData(null));
                    }}
                  >
                    Logout
                  </button>
                </>
              )}
            </form>
          </div>
        </div>
      </nav>
      {showPopup && (
        <div id="history-search" ref={historyPopupRef} style={popupStyles}>
          <div style={popupContentStyles}>
            <button style={closeButtonStyles} onClick={() => setShowPopup(false)}>X</button>
            <p style={{fontSize:"18px"}}>Register for <span style={{ color: 'red' }}>FREE</span> and get:</p>
            <ul>
              <li><PiDotOutlineFill /> 3 Daily searches</li>
              <li><PiDotOutlineFill /> Your searches [historical]</li>
            </ul>
            <button
              className="btn btn-primary"
              onClick={() => {
                setShowPopup(false);
                onLoginClick();
              }}
            >
              CREATE FREE ACCOUNT
            </button>
          </div>
        </div>
      )}
    </>
  );
}

const popupStyles = {
  position: 'absolute',
  top: '60px',
  right: '20px',
  backgroundColor: 'white',
  color: 'black',
  padding: '20px',
  borderRadius: '5px',
  boxShadow: '0 0 10px rgba(0,0,0,0.5)',
  zIndex: 1000,
};

const popupContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const closeButtonStyles = {
  alignSelf: 'flex-end',
  background: 'none',
  border: 'none',
  color: 'black',
  fontSize: '1rem',
  cursor: 'pointer',
};

export default Navbar;
